<template>
  <div id="main">
    <DFSHeader active="检索" />
    <SearchContent />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import SearchContent from './components/SearchContent.vue';

export default {
    name: 'Search',
    components: {
      DFSHeader,
      DFSFooter,
      SearchContent
    },
  data() {
    return {
    }
  }
}
</script>