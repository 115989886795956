<template>
  <div id="main-content">
    <a-row class="search">
      <a-col class="search-content" :span="16" :offset="4">
        <a-auto-complete
          class="search-content-autocomplete"
          style="width: 100%"
          option-label-prop="value"

        >
        <!-- @search="onSearch" -->
          <!-- @select="onSelect" -->
          <a-input
            v-model="form.q"
            placeholder="请输入物种名称、拉丁名、异名"
            style="height: 3.5rem;"
          ></a-input>
          <!-- <template slot="dataSource">
            <a-select-option
              v-for="taxon in taxons"
              :key="taxon.slug"
              :value="taxon.slug"
            >
              {{taxon.slug}}：<i>{{taxon.sciname}}</i>&nbsp;&nbsp;{{taxon.name}}
            </a-select-option>
          </template> -->
        </a-auto-complete>
        <div class="search-content-toolkit">
          <div class="search-content-toolkit-advanced-search">
            <a @click="onAdvancedSearchButtonClick">高级搜索</a>
          </div>
          <div class="search-content-toolkit-demo-text">
            例如：
            <span class="demo-text" @click="onSetDemoText('丽郝氏蠊')">丽郝氏蠊</span>、
            <i class="demo-text" @click="onSetDemoText('Hebardina concinna(Haan, 1842)')">Hebardina concinna(Haan, 1842)</i>
          </div>
          <div style="clear: both;"></div>
          <div class="search-conditions" v-show="showConditions">
            <a-form
              name="basic"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 16 }"
              autocomplete="off"
            >
              <a-form-item
                label="名称："
              >
                <a-input v-model="form.name" placeholder="请输入名称"/>
              </a-form-item>
              <a-form-item
                label="拉丁名："
              >
                <a-input v-model="form.sciname" placeholder="请输入拉丁名"/>
              </a-form-item>
              <a-form-item
                label="异名："
              >
                <a-input v-model="form.synonym" placeholder="请输入异名"/>
              </a-form-item>
              <a-form-item
                label="分布地："
              >
                <a-select
                  ref="select"
                  v-model="form.distribution"
                >
                  <a-select-option :value="p" v-for="(p, index) in province" :key="index">{{p}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                label="采集地："
              >
                <a-select
                  ref="select"
                  v-model="form.collection"
                >
                  <a-select-option :value="p" v-for="(p, index) in province" :key="index">{{p}}</a-select-option>
                </a-select>
              </a-form-item>

            </a-form>
          </div>
        </div>
      </a-col>
      <a-col class="search-button" :span="2" :offset="1">
        <a-button type="primary" @click="clickSearch">搜索</a-button>
      </a-col>
    </a-row>
    <a-divider orientation="left">使用帮助</a-divider>
    <a-descriptions :column="1" layout="vertical">
      <a-descriptions-item label="1、正常搜索支持通过哪些方式进行搜索">
        支持中文名、拉丁名、异名进行模糊查询。
      </a-descriptions-item>
      <a-descriptions-item label="2、什么是高级搜索">
        高级搜索支持更多字段，更精细。比如，支持采集地、分布地等方式的搜索。
      </a-descriptions-item>
      <a-descriptions-item label="3、如何进行高级搜索">
        在搜索页面点击高级搜索按钮，输入相应的关键字即可。例如：
        <br /><br />
        （1）中文名为丽郝氏蠊的物种。
        <br />
        （2）拉丁名为Hebardina concinna(Haan, 1842)的物种。
        <br />
        （3）分布地包含广东省的物种。
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import {searchSpecies} from '@/services/species';


export default {
  name: 'SearchContent',
  data() {
    return {
      q: '',
      taxons: [],
      slug: '',
      form: {
        q: '',
        //名称
        name:  '',
        //拉丁名称
        sciname:  '',
        //异名称
        synonym:  '',
        //分布地
        distribution:  '',
        //采集地
        collection:  '',
      },
      showConditions: false,
      province: [
        '北京市',
        '天津市',
        '河北省',
        '山西省',
        '内蒙古自治区',
        '辽宁省',
        '吉林省',
        '黑龙江省',
        '上海市',
        '江苏省',
        '浙江省',
        '安徽省',
        '福建省',
        '江西省',
        '山东省',
        '河南省',
        '湖北省',
        '湖南省',
        '广东省',
        '广西壮族自治区',
        '海南省',
        '重庆市',
        '四川省',
        '贵州省',
        '云南省',
        '西藏自治区',
        '陕西省',
        '甘肃省',
        '青海省',
        '宁夏回族自治区',
        '新疆维吾尔自治区',
        '台湾省',
        '香港特别行政区',
        '澳门特别行政区'
        ],
    };
  },
  methods: {
    onSearch() {
      searchSpecies(this.q).then(res => {
        this.taxons = [...res.data.data.results];
      });
    },
    onSelect(value) {
      this.$router.push('/species/' + value);
    },
    onSetDemoText(value) {
      console.log(value);
    },
    onClickSearchButton() {
    },
    onAdvancedSearchButtonClick() {
      this.showConditions = !this.showConditions
    },
    clickSearch() {
      let query = this.form

      this.$router.push({
        name: 'SearchList',
        query: query
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~/src/assets/styles/variables.styl"


#main-content
  padding-top: 2rem
  .search
    margin-bottom: 2rem
    .search-content-autocomplete
      margin-bottom: 1rem
    .search-content-toolkit
      position relative
    .search-conditions
        width 100%
        box-sizing border-box
        padding 20px
        position absolute
        top 25px
        left 0
        z-index 100
        box-shadow 0 0 6px #666
        background #fff
    .search-content-toolkit-advanced-search
      float: left
    .search-content-toolkit-demo-text
      float: right
    .demo-text
      color: $colorPrimary
    .demo-text:hover
      cursor: pointer
</style>
